import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { ReactComponent as One } from './1.svg'
import { ReactComponent as Two } from './2.svg'
import { ReactComponent as Three } from './3.svg'
import { up } from 'styled-breakpoints'

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;

  > * {
    flex-shrink: 0
  }

  svg {
    fill: currentColor;
    width: 80px;
    ${up('md')} {
      width: 200px;
    }
  }

  ${({ color, theme }) =>
    color === 'primary' &&
    css`
      color: ${theme.palette.button.primary.color};
    `}

  ${({ color, theme }) =>
    color === 'secondary' &&
    css`
      color: ${theme.palette.button.secondary.color};
    `}

  ${({ color, theme }) =>
    color === 'white' &&
    css`
      color: white;
    `}
`

const FlexibleLogo = props => {
  const { className, color } = props

  return (
    <Styled {...{ className, color }}>
      <One />
      <Two />
      <Three />
    </Styled>
  )
}

FlexibleLogo.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'white']),
}

FlexibleLogo.defaultProps = {
  color: 'primary',
}

export default FlexibleLogo
