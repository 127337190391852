import React from 'react'
import PropTypes from 'prop-types'
import FeatherIcon from 'feather-icons-react'
import theme from '../../theme/theme'
import styled from 'styled-components'
import { ReactComponent as Instagram } from './instagram.svg'
import { ReactComponent as LinkedIn } from './linkedin.svg'

const Styled = styled(FeatherIcon)`
  /* Reset after sanatize */
  fill: none;
`

const Icon = props => {
  const { icon, size, ...passProps } = props

  if (icon === 'instagram') {
    return <Instagram {...passProps} />
  }

  if (icon === 'linkedin') {
    return <LinkedIn {...passProps} />
  }

  return <Styled {...{ icon, size, ...passProps }} />
}

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf(theme.icons).isRequired,
  size: PropTypes.number,
}

Icon.defaultProps = {
  icon: theme.icons[0],
  size: 18,
}

export default Icon
