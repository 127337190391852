import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'

const Styled = styled(Img)`
  max-width: 100%;
  position: relative;
  background: #666;

  ${({ margin, theme }) =>
    margin &&
    css`
      margin: 0 auto;
    `}
`

const Image = props => {
  return <Styled {...props} />
}

Image.propTypes = {
  className: PropTypes.any,
  sources: PropTypes.array,
  alt: PropTypes.string,
  margin: PropTypes.bool,
  fluid: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fixed: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Image
