import React, { useState, useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import RModal from 'react-modal'

import IconButton from '../IconButton/IconButton'

const ReactModalAdapter = ({ className, ...props }) => {
  const contentClassName = `${className}__content`
  const overlayClassName = `${className}__overlay`
  return (
    <RModal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    />
  )
}

const Styled = styled(ReactModalAdapter)`
  &__overlay {
    z-index: ${({ zIndex }) => zIndex};
    background: white;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
    transition-duration: ${({ closeTimeoutMS }) => closeTimeoutMS}ms;

    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }

    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: auto;
    background: white;

    ${({ color, theme }) =>
      color === 'primary' &&
      css`
        background-color: ${({ theme }) => theme.palette.primary.main};
      `}

    ${({ color, theme }) =>
      color === 'secondary' &&
      css`
        background-color: ${({ theme }) => theme.palette.secondary.main};
      `}

    ${({ color }) =>
      color === 'white' &&
      css`
        background-color: white;
      `}
  }
`

const Close = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  margin: ${({ theme }) => theme.spacing(2, 'px')};
`

const Modal = props => {
  const {
    className,
    onAfterOpen,
    onAfterClose,
    onRequestClose,
    children,
    visible,
    closeTimeoutMS,
    appElSelector,
    hideClose,
    zIndex,
    color,
  } = props

  const [documentLoaded, setDocumentLoaded] = useState(false)

  useLayoutEffect(() => {
    RModal.setAppElement(appElSelector)
    setDocumentLoaded(true)
  }, [appElSelector])

  const handleOnAfterOpen = () => {
    document.body.style.overflow = 'hidden'
    if (onAfterOpen) onAfterOpen()
  }

  const handleOnAfterClose = () => {
    if (onRequestClose) onRequestClose()
    document.body.style.overflow = 'auto'
  }

  // Call this to properly hide your application from assistive screenreaders and other assistive technologies while the modal is open.

  return !documentLoaded ? (
    <div></div>
  ) : (
    createPortal(
      <Styled
        isOpen={visible}
        {...{
          color,
          className,
          onAfterOpen: handleOnAfterOpen,
          onAfterClose: handleOnAfterClose,
          onRequestClose,
          closeTimeoutMS,
          zIndex,
        }}
      >
        {!hideClose && (
          <Close
            color={color === 'secondary' ? 'white' : 'secondary'}
            icon="x"
            size={18}
            onClick={onRequestClose}
          />
        )}
        {children}
      </Styled>,
      document.body
    )
  )
}

Modal.propTypes = {
  className: PropTypes.string,
  appElSelector: PropTypes.string,
  onAfterOpen: PropTypes.func,
  onAfterClose: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  closeTimeoutMS: PropTypes.number,
  hideClose: PropTypes.bool,
  zIndex: PropTypes.number,
  color: PropTypes.oneOf(['primary', 'secondary', 'white']),
}

Modal.defaultProps = {
  closeTimeoutMS: 250,
  appElSelector: 'body',
  hideClose: false,
  zIndex: 101,
}

export default Modal
