import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import MainMenuToggle from '../../components/MainMenuToggle/MainMenuToggle'
import Modal from '../../components/Modal/Modal'
import { Box } from '@material-ui/core'
import FlexibleLogo from '../../components/FlexibleLogo/FlexibleLogo'
import Container from '../../components/Container'
import NavList from '../../components/NavList/NavList'
import Typography from '../../components/Typography/Typography'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 30px;
  justify-content: space-between;
  max-width: calc(100% - 80px);

  ${up('sm')} {
    padding-top: 60px;
  }

  ${up('md')} {
    padding-top: 150px;
  }
`

const StyledNavList = styled(NavList)`
  padding-bottom: 90px;
`

const StyledFlexibleLogo = styled(FlexibleLogo)`
  position: fixed;
`

const MainMenu = props => {
  const { appElSelector } = props
  const [visible, setVisible] = React.useState(false)

  const handleOnMenuButtonClick = e => {
    e.preventDefault()
    setVisible(!visible)
  }

  const handleOnLinkClick = e => {
    setVisible(false)
  }

  const handleOnRequestClose = () => {
    setVisible(false)
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          allFocuses(
            filter: {
              slug: { nin: ["ohne-schublade", "kein-schwerpunkt"] }
              id: { ne: "dummy" }
            }
          ) {
            nodes {
              title
              slug
            }
          }
        }
      `}
      render={({ allFocuses: { nodes: focusNodes } }) => (
        <>
          <MainMenuToggle onClick={handleOnMenuButtonClick} active={visible} />
          <Modal
            {...{
              visible,
              hideClose: true,
              onRequestClose: handleOnRequestClose,
              closeTimeoutMS: 0,
              appElSelector: appElSelector,
              color: 'primary',
              shouldFocusAfterRender: false,
              zIndex: 100,
            }}
          >
            <StyledFlexibleLogo color="white" />
            <Container>
              <Content>
                <NavList variant="main" color="white">
                  {focusNodes.map((entry, key) => (
                    <Typography variant="mainNav" key={key}>
                      <Link
                        to={'/schwerpunkte/' + entry.slug}
                        onClick={handleOnLinkClick}
                      >
                        {entry.title}
                      </Link>
                    </Typography>
                  ))}
                </NavList>
                <StyledNavList variant="meta" color="white">
                  <Typography variant="metaNav">
                    <Link to="/" onClick={handleOnLinkClick}>
                      Startseite
                    </Link>
                  </Typography>
                  <Typography variant="metaNav">
                    <Link to="/projekte/" onClick={handleOnLinkClick}>
                      Projekte
                    </Link>
                  </Typography>
                  <Typography variant="metaNav">
                    <Link to="/profil/" onClick={handleOnLinkClick}>
                      Profil
                    </Link>
                  </Typography>
                  <Typography variant="metaNav">
                    <Link to="/karriere/" onClick={handleOnLinkClick}>
                      Karriere
                    </Link>
                  </Typography>
                  {/*
                  <Typography variant="metaNav">
                    <Link to="/stories" onClick={handleOnLinkClick}>
                      Stories
                    </Link>
                  </Typography>
                  */}
                </StyledNavList>
              </Content>
            </Container>
          </Modal>
        </>
      )}
    />
  )
}

MainMenu.propTypes = {
  appElSelector: PropTypes.string,
}

MainMenu.defaultProps = {
  appElSelector: '#___gatsby',
}

export default MainMenu
