import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import IconButton from '../IconButton/IconButton'

const Styled = styled(IconButton)`
  position: fixed;
  z-index: 101;
  bottom: ${({ theme }) => theme.spacing(5, 'px')};
  left: 50%;
  transform: translateX(-50%);
  transition: background-color 0.15s;

  ${({ active }) =>
    active &&
    css`
      .label {
        color: ${({ theme }) => theme.palette.primary.main};
      }
    `}
`

const MainMenuToggle = props => {
  const { className, active, ...passProps } = props

  return (
    <Styled
      icon={active ? 'x' : 'menu'}
      color={active ? 'white' : 'primary'}
      variant="menu"
      {...{ className, active, ...passProps }}
    />
  )
}

MainMenuToggle.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
}

MainMenuToggle.defaultProps = {
  //className: 'class'
}

export default MainMenuToggle
