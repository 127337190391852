import React from 'react'
import PropTypes from 'prop-types'
import theme from '../../theme/theme'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'

const Styled = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing(2.5, 'px')};
  max-width: 100%;

  ${up('sm')} {
    max-width: ${({ theme, maxWidth }) => theme.maxWidth(maxWidth)};
    margin-left: auto;
    margin-right: auto;
  }

  ${({ fullWidthMobile, theme }) =>
    fullWidthMobile &&
    css`
      padding: 0;
    `}
`

const Container = props => {
  const { maxWidth, children, className, fullWidthMobile, ...passProps } = props
  return (
    <Styled {...{ maxWidth, className, ...passProps, fullWidthMobile }}>
      {children}
    </Styled>
  )
}

Container.propTypes = {
  children: PropTypes.any,
  maxWidth: PropTypes.oneOf(Object.keys(theme.breakpoints)),
  fullWidthMobile: PropTypes.bool,
}

Container.defaultProps = {
  maxWidth: 'md',
  fullWidthMobile: false,
}

export default Container
