import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'

const Styled = styled.section`
  padding-top: ${({ theme }) => theme.spacing(7.5, 'px')};
  margin-bottom: ${({ theme }) => theme.spacing(7.5, 'px')};
  border-top: 1px solid currentColor;

  ${up('md')} {
    /* padding-top: ${({ theme }) => theme.spacing(12.5, 'px')};
    margin-bottom: ${({ theme }) => theme.spacing(12.5, 'px')}; */
  }

  ${({ color, theme }) =>
    color === 'primary' &&
    css`
      color: ${theme.palette.primary.main};
    `}

  ${({ color, theme }) =>
    color === 'secondary' &&
    css`
      color: ${theme.palette.secondary.main};
    `}

  ${({ border }) =>
    border === false &&
    css`
      border: none;
    `}

  ${({ flush }) =>
    flush &&
    css`
      && {
        padding-top: 0;
      }
    `}
`

const Section = props => {
  const { className, children, color, flush, border, passProps, id } = props
  return (
    <Styled id={id} {...{ className, color, flush, border, passProps }}>
      {children}
    </Styled>
  )
}

Section.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'transparent']),
  flush: PropTypes.bool,
  border: PropTypes.bool,
}

Section.defaultProps = {
  color: 'primary',
  flush: false,
  border: true,
}

export default Section
