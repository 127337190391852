import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import theme from '../../theme/theme'
import Icon from '../Icon/Icon'
import Typography from '../Typography/Typography'

const Styled = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(2, 'px')};
`

const StyledIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing(20 / 8, 'px')};
`

const Label = styled.div`
  overflow: hidden;
`

const IconLink = props => {
  const { className, icon, children } = props

  return (
    <Styled {...{ className }}>
      <StyledIcon {...{ icon, size: 32 }} />
      <Typography variant="iconLink">{children}</Typography>
    </Styled>
  )
}

IconLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.oneOf(theme.icons).isRequired,
}

IconLink.defaultProps = {
  //className: 'class'
}

export default IconLink
