import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Styled = styled.div`
  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(1.25, 'px')};
  }
`

const ButtonGroup = props => {
  const { children } = props
  return <Styled>{children}</Styled>
}

ButtonGroup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
}

export default ButtonGroup
