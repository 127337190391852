import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Container from '../Container/Container'
import { up } from 'styled-breakpoints'

const Styled = styled.div`
  position: relative;
  margin-top: -20px;
  width: 100%;
  padding-right: 85px;

  ${up('sm')} {
    max-width: 890px;
  }
`

const HeroContent = props => {
  const { children } = props
  return (
    <Container>
      <Styled>{children}</Styled>
    </Container>
  )
}

HeroContent.propTypes = {
  //
}

HeroContent.defaultProps = {
  //
}

export default HeroContent
