const cleanPhoneNumber = number => {
  if (typeof number !== 'string') {
    return ''
  }

  let result = ''

  for (let i = 0; i < number.length; i++) {
    if (number[i] === '+' && i === 0) {
      result += number[i]
      continue
    }
    const value = parseInt(number[i])

    if (isNaN(value)) {
      continue
    }

    result += value
  }

  return result
}

export default cleanPhoneNumber
