import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'
import get from 'lodash/get'

const Styled = styled.span`
  display: ${({ as }) => (as === 'div' ? 'block' : 'inline-block')};
  font-family: ${({ theme }) => theme.typo.primary};
  font-weight: ${({ theme }) => theme.typo.weight.regular};

  a {
    color: currentColor;
    text-decoration: none;
  }

  p {
    margin-bottom: 0.75em;
  }

  ${({ medium }) =>
    medium &&
    css`
      & {
        font-weight: ${({ theme }) => theme.typo.weight.medium};
      }
    `}

  ${({ inline }) =>
    inline &&
    css`
      & {
        display: inline;
      }
    `}

  ${({ copy }) =>
    copy &&
    css`
      & {
        font-weight: ${({ theme }) => theme.typo.weight.medium};
      }
    `}

  ${({ paragraph }) =>
    paragraph &&
    css`
      display: block;
      margin-bottom: 0.75em;
    `}

  ${({ variant }) =>
    variant === 'h1' &&
    css`
      font-size: 36px;
      line-height: 1.4375;
      margin-bottom: ${({ paragraph }) => (paragraph ? '1.38em' : '0.5em')};
    `}

  ${({ variant }) =>
    variant === 'h2' &&
    css`
      font-size: 24px;
      line-height: 1.34;
    `}

  ${({ variant }) =>
    variant === 'h3' &&
    css`
      font-size: 24px;
      line-height: 1.34;
      margin-bottom: ${({ paragraph }) => (paragraph ? '1.25em' : 0)};
    `}

  ${({ variant }) =>
    variant === 'body' &&
    css`
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: ${({ paragraph }) => (paragraph ? '1.5em' : 0)};
    `}

  ${({ variant }) =>
    variant === 'teaser' &&
    css`
      font-size: 24px;
      line-height: 1.34;
      margin-bottom: ${({ paragraph }) => (paragraph ? '1.5em' : 0)};
    `}

  ${({ variant }) =>
    variant === 'projectTileTeaser' &&
    css`
      font-size: 14px;
      line-height: 1.43;
      margin-bottom: 1.42em;
    `}

  ${({ variant }) =>
    variant === 'projectTileHeader' &&
    css`
      font-size: 24px;
      line-height: 1.33;
      margin-bottom: 0.42em;
    `}

  ${({ variant }) =>
    variant === 'buttonLabel' &&
    css`
      font-size: 14px;
      font-weight: ${({ theme }) => theme.typo.weight.medium};
    `}

  ${({ variant }) =>
    variant === 'tabLabel' &&
    css`
      font-size: 14px;
      text-transform: none;
      font-weight: ${({ theme }) => theme.typo.weight.medium};
    `}

  ${({ variant }) =>
    variant === 'heroLabel' &&
    css`
      font-size: 18px;
      line-height: 1.4;
    `}

  ${({ variant }) =>
    variant === 'hero' &&
    css`
      font-size: 32px;
      line-height: 1.2;

      ${up('sm')} {
        font-size: 40px;
      }

      ${up('md')} {
        font-size: 64px;
      }
    `}

  ${({ variant }) =>
    variant === 'careerTeaserLabel' &&
    css`
      font-size: 16px;
      line-height: 1.4;
    `}

  ${({ variant }) =>
    variant === 'careerTeaser' &&
    css`
      font-size: 40px;
      line-height: 1.2;
    `}

  ${({ variant }) =>
    variant === 'fullTeaser' &&
    css`
      font-size: 36px;
      line-height: 1.2;
    `}

  ${({ variant }) =>
    variant === 'mainNav' &&
    css`
      font-size: 24px;
      line-height: 1.33;

      ${up('sm')} {
        font-size: 36px;
      }
    `}

  ${({ variant }) =>
    variant === 'metaNav' &&
    css`
      font-size: 18px;
      line-height: 1.56;
    `}

  ${({ variant }) =>
    variant === 'accordionHeader' &&
    css`
      font-size: 18px;
      font-weight: ${({ theme }) => theme.typo.weight.medium};
      line-height: 1.44;
    `}

  ${({ variant }) =>
    variant === 'accordionHeaderBig' &&
    css`
      font-size: 36px;
      font-weight: normal;
      line-height: 1;
    `}

  ${({ variant }) =>
    variant === 'personName' &&
    css`
      font-size: 18px;
      font-weight: ${({ theme }) => theme.typo.weight.medium};
      line-height: 1.44;
    `}

  ${({ variant }) =>
    variant === 'personPos' &&
    css`
      font-size: 18px;
      font-weight: ${({ theme }) => theme.typo.weight.regular};
      line-height: 1.44;
    `}

  ${({ variant }) =>
    variant === 'tableHeader' &&
    css`
      font-size: 16px;
      font-weight: ${({ theme }) => theme.typo.weight.medium};
      line-height: 1.44;
    `}

  ${({ variant }) =>
    variant === 'tableCell' &&
    css`
      /* max-width: 100%; */
      font-size: 16px;
      font-weight: ${({ theme }) => theme.typo.weight.regular};
      line-height: 1.2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}

  ${({ variant }) =>
    variant === 'detailsTable' &&
    css`
      /* max-width: 100%; */
      font-size: 16px;
      font-weight: ${({ theme }) => theme.typo.weight.regular};
      line-height: 1.2;
    `}

  ${({ variant }) =>
    variant === 'factsLabel' &&
    css`
      font-size: 18px;
      line-height: 1.44;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
    `}

  ${({ variant }) =>
    variant === 'factsFact' &&
    css`
      font-size: 56px;
      line-height: 1;
    `}

  ${({ variant }) =>
    variant === 'iconLink' &&
    css`
      font-size: 24px;
      line-height: 1.33;
    `}

  ${({ variant }) =>
    variant === 'listTitle' &&
    css`
      font-size: 16px;
      font-weight: ${({ theme }) => theme.typo.weight.medium};
      line-height: 1.2;
    `}

  ${({ variant }) =>
    variant === 'projectTitleLabel' &&
    css`
      display: inline-block;
      white-space: nowrap;
      font-size: 12px;
      line-height: 1;
      font-weight: ${({ theme }) => theme.typo.weight.medium};
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${({ theme }) => get(theme, `palette.${color}.main`, color)};
    `}


  ${({ variant }) =>
    variant === 'newsMeta' &&
    css`
      font-size: 14px;
      line-height: 1.43;
    `}

`

const Typography = props => {
  const {
    children,
    variant,
    className,
    paragraph,
    as,
    color,
    medium,
    inline,
  } = props
  return (
    <Styled {...{ className, variant, paragraph, as, color, medium, inline }}>
      {children}
    </Styled>
  )
}

Typography.propTypes = {
  children: PropTypes.any,
  as: PropTypes.string,
  color: PropTypes.string,
  medium: PropTypes.bool,
  inline: PropTypes.bool,
}

Typography.defaultProps = {
  as: 'span',
  color: null,
  medium: false,
  inline: false,
}

export default Typography
