import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Styled = styled.div`
  background: #666;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

const ColorOverlay = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, ${({ darken }) => darken});
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const FullImg = styled(Img)`
  width: 100%;
  height: 100%;
`

const BackgroundImage = props => {
  const { className, fluid, alt, title, darken } = props

  return (
    <Styled {...{ className }}>
      {fluid && (
        <FullImg
          {...{
            fluid,
            objectFit: 'cover',
            objectPosition: '50% 50%',
            alt,
            title,
          }}
        />
      )}
      {darken > 0 && <ColorOverlay darken={darken} />}
    </Styled>
  )
}

BackgroundImage.propTypes = {
  className: PropTypes.any,
  sources: PropTypes.array,
  src: PropTypes.string,
  alt: PropTypes.string,
  darken: PropTypes.number,
  fluid: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

BackgroundImage.defaultProps = {
  darken: 0,
}

export default BackgroundImage
