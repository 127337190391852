import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Styled = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  mix-blend-mode: ${({ multiply }) => multiply ? 'multiply' : 'unset'};

  ${({ color, theme }) =>
    color === 'primary' &&
    css`
      opacity: 0.7;
      background-image: radial-gradient(
        circle at 100% 100%,
        #ff3c19,
        rgba(255, 60, 25, 0) 83%
      );
    `}

  ${({ color, theme }) =>
    color === 'secondary' &&
    css`
      opacity: 0.5;
      background-image: linear-gradient(
        to bottom,
        #000000 14%,
        rgba(0, 0, 0, 0) 41%,
        #000000 94%
      );
    `}

  ${({ color, theme }) =>
    color === 'fullTeaser' &&
    css`
      opacity: 0.7;
      background-image: radial-gradient(
        circle at 0% 0%,
        #ff3c19,
        rgba(255, 60, 25, 0) 84%
      );
    `}

  ${({ color, theme }) =>
    color === 'bottomLeft' &&
    css`
      opacity: 0.7;
      background-image: radial-gradient(
        circle at 0% 100%,
        #ff3c19,
        rgba(255, 60, 25, 0) 51%
      );
    `}
`

const GradientOverlay = props => {
  const { className, color, multiply } = props

  return <Styled {...{ className, color, multiply }}></Styled>
}

GradientOverlay.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'fullTeaser', 'bottomLeft', 'projects']),
  multiply: PropTypes.bool,
}

GradientOverlay.defaultProps = {
  color: 'primary',
  multiply: false,
}

export default GradientOverlay
