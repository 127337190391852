import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'

const Styled = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: ${({ theme }) => theme.spacing(100 / 8, 'px')};
  grid-row-gap: ${({ theme }) => theme.spacing(20 / 8, 'px')};

  ${({ variant, theme }) =>
    variant === '2x' &&
    css`
      grid-row-gap: ${({ theme }) => theme.spacing(50 / 8, 'px')};
      ${up('md')} {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: ${({ theme }) => theme.spacing(100 / 8, 'px')};
      }
    `}

  ${({ variant, theme }) =>
    variant === '4x' &&
    css`
      grid-row-gap: ${({ theme }) => theme.spacing(40 / 8, 'px')};
      ${up('sm')} {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: ${({ theme }) => theme.spacing(40 / 8, 'px')};
      }

      ${up('md')} {
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: ${({ theme }) => theme.spacing(50 / 8, 'px')};
      }
    `}

    ${({ gap }) =>
      gap === false &&
      css`
        ${up('md')} {
          grid-column-gap: 0;
          grid-row-gap: 0;
        }
      `}
`

const Grid = props => {
  const { className, children, variant, gap } = props

  return <Styled {...{ className, variant, gap }}>{children}</Styled>
}

Grid.propTypes = {
  children: PropTypes.any,
  variant: PropTypes.oneOf(['4x', '2x']),
  className: PropTypes.string,
  gap: PropTypes.bool,
}

Grid.defaultProps = {
  variant: '2x',
  gap: true,
}

export default Grid
