import React from 'react'
import { Helmet } from 'react-helmet'
import MainMenu from '../regions/MainMenu/MainMenu'
import Footer from 'src/regions/Footer'

const Layout = ({ children, ...props }) => {
  const {
    contact,
    meta,
  } = props

  const {
    metaTitle,
    metaDescription,
    metaImage,
  } = meta || {}

  return (
    <React.Fragment>
      <Helmet>
        {metaTitle       && <title>{metaTitle}</title>}
        {metaDescription && <meta name="description" content={metaDescription} />}
        {metaTitle       && <meta property="og:title" content={metaTitle} />}
        {metaDescription && <meta property="og:description" content={metaDescription} />}
                            <meta property="og:site_name" content="Hahn Helten Architektur" />
                            <meta property="og:type" content="website" />
        {metaImage       && <meta property="og:image" content={metaImage.childImageSharp.fixed.src} />}
        {metaImage       && <meta property="og:image:width" content={metaImage.childImageSharp.fixed.width} />}
        {metaImage       && <meta property="og:image:height" content={metaImage.childImageSharp.fixed.height} />}
                            <meta name="twitter:title" content={metaTitle} />
        {metaDescription && <meta name="twitter:description" content={metaDescription} />}
        {metaImage       && <meta name="twitter:image" content={metaImage.childImageSharp.fixed.src} />}
                            <meta name="twitter:card" content={`summary${metaImage ? '_large_image' : ''}`} />
      </Helmet>
      <main>{children}</main>
      <MainMenu />
      <Footer {...contact} />
    </React.Fragment>
  )
}

export default Layout
