import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Section from 'src/components/Section'
import Container from 'src/components/Container'
import Grid from 'src/components/Grid'
import Typography from 'src/components/Typography'
import { Box } from '@material-ui/core'
import ButtonGroup from 'src/components/ButtonGroup'
import Button from 'src/components/Button'
import IconLink from 'src/components/IconLink'
import { up } from 'styled-breakpoints'
import { ReactComponent as Logo } from 'src/../static/assets/img/hha_logo.svg'
import { Link } from 'gatsby'
import cleanPhoneNumber from 'src/helper/cleanPhoneNumber'
import Image from 'src/components/Image'
import { graphql } from 'gatsby'

const FollowBox = styled(Box)`
  ${up('md')} {
    margin: 0 auto;
  }
`

const Meta = styled(Box)`
  ${up('md')} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const MetaNav = styled.div`
  ${up('md')} {
    display: flex;
  }
`

const FooterLogo = styled(Logo)`
  color: ${({ theme }) => theme.palette.secondary.main};
  width: 140px;
  margin-bottom: ${({ theme }) => theme.spacing(2.5, 'px')};

  ${up('md')} {
    margin-bottom: 0;
  }
`

const Copyright = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(3.75, 'px')};

  ${up('md')} {
    margin-right: 20px;
    margin-bottom: 0;
  }
`

const Links = styled.div`
  > *:not(:first-child) {
    margin-left: 20px;
  }
`

const Contact = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(30 / 8, 'px')};
`

const ContactImage = styled(Image)`
  width: 100px;
  margin-right: ${({ theme }) => theme.spacing(20 / 8, 'px')};
`

const contactDefaults = {
  contactTitle: 'Neugierig?',
  contactText:
    'Sie interessieren sich für uns oder haben noch offene Fragen? Lassen Sie uns darüber sprechen.',
  person: {
    avatar: null,
    phone: '0241 · 900 399 0',
    email: 'mail@hahn-helten.de',
  },
  address: {
    street: 'Schurzelter Straße 27',
    zip: '52074',
    city: 'Aachen',
    mapLink: 'https://goo.gl/maps/f1EpbpELR7i3LY5S7',
  },
}

const Footer = props => {
  let { className, contactTitle, contactText, contact } = props

  // when the values are empty strings
  contactTitle = contactTitle ? contactTitle : contactDefaults.contactTitle
  contactText = contactText ? contactText : contactDefaults.contactText
  const contactPerson =
    contact && contact.avatar ? contact : contactDefaults.person
  const { address } = contactDefaults

  return (
    <>
      <Section color="primary" {...{ className }}>
        <Container>
          <Grid variant="2x" gap={false}>
            <Box maxWidth={440}>
              <Typography variant="h1">{contactTitle}</Typography>
              <Typography variant="body" paragraph>
                {contactText}
              </Typography>
              {contactPerson && (
                <Contact>
                  {contactPerson.avatar && contactPerson.avatar.local && (
                    <ContactImage
                      fluid={contactPerson.avatar.local.childImageSharp.fluid}
                    />
                  )}
                  <Box>
                    <Typography variant="body" as="div">
                      <strong>
                        {contactPerson.firstName} {contactPerson.lastName}
                      </strong>
                    </Typography>
                    {contactPerson.position && (
                      <Typography variant="body" as="div">
                        {contactPerson.position}
                      </Typography>
                    )}
                  </Box>
                </Contact>
              )}
              {contactPerson.phone && (
                <Typography variant="h1">{contactPerson.phone}</Typography>
              )}
              <Typography variant="body" paragraph>
                {address.street}
                <br />
                {address.zip} {address.city}
              </Typography>
              <ButtonGroup>
                {contactPerson.phone && (
                  <Button
                    color="primary"
                    href={'tel:' + cleanPhoneNumber(contactPerson.phone)}
                  >
                    Anrufen
                  </Button>
                )}
                <Button
                  color="primary"
                  href={'mailto:' + contactDefaults.person.email}
                >
                  E-Mail schreiben
                </Button>
                <Button color="primary" href={address.mapLink}>
                  Anfahrt
                </Button>
              </ButtonGroup>
            </Box>
            <FollowBox>
              <Typography variant="h1">Follow us</Typography>

              <IconLink icon="instagram">
                <a
                  href="https://www.instagram.com/hahnhelten"
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                >
                  Auf Instagram folgen
                </a>
              </IconLink>
              <IconLink icon="linkedin">
                <a
                  href="https://de.linkedin.com/company/hahn-helten-architektur"
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                >
                  Auf LinkedIn folgen
                </a>
              </IconLink>
            </FollowBox>
          </Grid>
        </Container>
      </Section>
      <Section color="secondary">
        <Container>
          <Meta>
            <Box>
              <Link to="/">
                <FooterLogo />
              </Link>
            </Box>
            <MetaNav>
              <Copyright variant="body">
                © {new Date().getFullYear()} · Hahn Helten Architektur
              </Copyright>
              <Links>
                <Typography variant="body">
                  <Link to="/impressum">Impressum</Link>
                </Typography>
                <Typography variant="body">
                  <Link to="/datenschutz">Datenschutz</Link>
                </Typography>
              </Links>
            </MetaNav>
          </Meta>
        </Container>
      </Section>
    </>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
  contactTitle: PropTypes.string,
  contactText: PropTypes.string,
  contactPhone: PropTypes.string,
  contactMail: PropTypes.string,
}

Footer.defaultProps = contactDefaults

export const query = graphql`
  fragment ContactFragment on users {
    title
    firstName
    lastName
    position
    phone
    email
    description
    avatar {
      local {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`

export default Footer
