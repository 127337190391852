import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'

const Styled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: stretch;
  color: white;
  min-height: 520px;
  background: ${({ transparent }) => (transparent ? 'transparent' : '#666')};

  ${up('sm')} {
    min-height: 640px;
  }

  ${up('md')} {
    min-height: 100vh;
  }
`

const Hero = props => {
  const { className, children, transparent } = props

  return <Styled {...{ className, transparent }}>{children}</Styled>
}

Hero.propTypes = {
  transparent: PropTypes.bool,
}

Hero.defaultProps = {
  transparent: false,
}

export default Hero
