import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import ReactMarkdown from 'react-markdown/with-html'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  && {
    text-decoration: underline;
  }
`

const StyledReactMarkdown = styled(ReactMarkdown)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
  }

  p:first-child {
    margin-top: 0;
  }

  hr {
    border: none;
    border-bottom: 1px solid;
    margin: 4rem 0;
    opacity: 0.5;
  }
`

const Markdown = props => {
  const { className, source } = props

  const renderers = {
    link: ({ children, href, ...props }) => (
      <StyledLink to={href} {...props}>
        {children}
      </StyledLink>
    ),
  }

  return (
    <StyledReactMarkdown
      source={source}
      renderers={renderers}
      escapeHtml={false}
      className={className}
    />
  )
}

Markdown.propTypes = {
  source: PropTypes.string,
}

Markdown.defaultProps = {
  //
}

export default Markdown
