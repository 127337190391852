import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Box } from '@material-ui/core'
import { up } from 'styled-breakpoints'

const ListItem = styled.div`
  ${({ variant, theme }) =>
    variant === 'main' &&
    css`
      &:not(:last-child) {
        margin-bottom: ${theme.spacing(2.5, 'px')};

        ${up('sm')} {
          margin-bottom: ${theme.spacing(6.25, 'px')};
        }
      }
    `}

  ${({ variant, spacer, theme }) =>
    variant === 'meta' &&
    css`
      margin-bottom: ${theme.spacing(1, 'px')};

      &:not(:last-child):after {
        content: ${spacer ? "'·'" : "''"};
        color: currentColor;
        display: inline-block;
        margin: 0 ${theme.spacing(10 / 8, 'px')};

        ${up('sm')} {
          margin: 0 ${theme.spacing(15 / 8, 'px')};
        }
      }
    `}
`

const Styled = styled(Box)`
  ${({ color, theme }) =>
    color === 'primary' &&
    css`
      color: ${theme.palette.primary.main};
    `}

  ${({ color, theme }) =>
    color === 'white' &&
    css`
      color: white;
    `}

  ${({ color, theme }) =>
    color === 'secondary' &&
    css`
      color: ${theme.palette.secondary.main};
    `}

  ${({ variant, theme }) =>
    variant === 'meta' &&
    css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    `}

  ${({ variant, theme }) =>
    variant === 'main' &&
    css`
      display: block;
      margin-bottom: ${({ theme }) => theme.spacing(60 / 8, 'px')};
    `}
`

const NavList = props => {
  const { className, variant, children, color, spacer } = props

  return (
    <Styled {...{ className, variant, color }}>
      {children &&
        children.map((child, idx) => {
          return <ListItem {...{ variant, key: idx, spacer }}>{child}</ListItem>
        })}
    </Styled>
  )
}

NavList.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['main', 'meta']),
  spacer: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'white']),
}

NavList.defaultProps = {
  variant: 'meta',
}

export default NavList
