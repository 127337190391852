import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Typography from '../Typography/Typography'
import { Link } from 'gatsby'

const Label = styled(Typography)`
  display: inline-block;
  white-space: nowrap;
`

const Styled = styled.button`
  text-decoration: none;
  outline: 0;
  border: 0;
  display: inline-flex;
  align-items: center;
  margin: 0;
  justify-content: center;
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0 ${({ theme }) => theme.spacing(2.5, 'px')};
  height: 40px;
  border-radius: 25px;
  background: none;
  cursor: pointer;
  -webkit-appearance: none;

  ${({ disabled }) =>
    disabled === true &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  ${({ color, theme }) =>
    color === 'primary' &&
    css`
      color: ${theme.palette.button.primary.color};
      border: 1px solid currentColor;
    `}

  ${({ color, theme }) =>
    color === 'secondary' &&
    css`
      color: ${theme.palette.button.secondary.color};
      border: 1px solid currentColor;
    `}

  ${({ color, theme }) =>
    color === 'black' &&
    css`
      color: black;
      border: 1px solid black;
    `}

  ${({ color, theme }) =>
    color === 'white' &&
    css`
      color: white;
      border: 1px solid white;
    `}

  ${({ color, theme }) =>
    color === 'link' &&
    css`
      color: inherit;
      border: 1px solid transparent;

      ${Label} {
        border-bottom: 1px solid currentColor;
      }
    `}

  ${({ variant, theme }) =>
    ['contained', 'menu'].includes(variant) &&
    css`
      background: currentColor;

      ${Label} {
        color: ${({ color }) => (color === 'white' ? 'black' : 'white')};
      }
    `}
`

const Button = props => {
  const {
    className,
    color,
    type,
    children,
    to,
    href,
    target,
    variant,
    ...passProps
  } = props

  const handleOnClick = (e, to) => {
    e.preventDefault()
    const el = document.querySelector(to)
    if (window && document && el) {
      window.scrollTo({
        top: el.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  let as = 'button'

  if (to) {
    as = to && to.startsWith('#') ? 'button' : Link
  } else if (href) {
    as = 'a'
  }

  return (
    <Styled
      {...{
        className,
        color,
        variant,
        type: to ? null : type,
        onClick: to && to.startsWith('#') ? e => handleOnClick(e, to) : null,
        href,
        to,
        as,
        target,
        ...passProps,
      }}
    >
      <Label variant="buttonLabel" className="label">
        {children}
      </Label>
    </Styled>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  to: PropTypes.string,
  target: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'contained', 'menu']),
  color: PropTypes.oneOf(['primary', 'secondary', 'black', 'white', 'link']),
}

Button.defaultProps = {
  type: 'button',
  color: 'primary',
  variant: 'default',
}

export default Button
