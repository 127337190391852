import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Button from '../Button/Button'
import theme from '../../theme/theme'
import Icon from '../Icon/Icon'

const Styled = styled(Button)`
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 100%;

  ${({ variant, theme }) =>
    variant === 'menu' &&
    css`
      width: 50px;
      height: 50px;
    `}
`

const IconButton = props => {
  const { icon, size, color, variant, className, ...passProps } = props
  return (
    <Styled {...{ color, variant, className, ...passProps }}>
      <Icon className="icon" icon={icon} size={size} />
    </Styled>
  )
}

IconButton.propTypes = {
  icon: PropTypes.oneOf(theme.icons).isRequired,
  size: PropTypes.number,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'contained', 'menu']),
  color: PropTypes.oneOf(['primary', 'secondary', 'white']),
}

IconButton.defaultProps = {
  icon: theme.icons[0],
  size: 18,
  color: 'primary',
  variant: 'default',
}

export default IconButton
